:root {
    --spacer-size: 125px;
}

.main_content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.spacer {
    flex: 1;
    min-height: var(--spacer-size);
}

#headline_container {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex-direction: column;
}

#headline_container p {
    margin: 0;
    text-align: right;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    display: block;
    padding: calc(var(--spacer-size) - 60px) 0;
    max-width: 880px;
    font-size: 28px;
    color: #f5f5f5;
    text-align: center;
}

#headline_container img {
    height: 70px;
    margin: 0;
}

#logo {
    height: 24px;
}

@media only screen and (max-width: 1230px) {
    :root {
        --spacer-size: 140px;
    }

    .header_footer {
        font-size: 16px;
        padding: 0 40px;
    }

    #headline_container p {
        font-size: 22px;
        max-width: 700px;
    }

    #headline_container img {
        width: 56px;
    }
}

@media screen and (max-width: 780px) {
    :root {
        --spacer-size: 120px;
    }

    body {
        padding: 16px 0;
    }

    #logo {
        height: 20px;
    }

    .header_footer {
        padding: 0 16px;
        flex-direction: column;
        align-items: start;
        gap: 22px;
        font-size: 20px;
    }

    p.header_footer_items {
        text-align: center;
        width: 100%;
    }

    #headline_container {
        padding: 0 16px;
    }

    #headline_container img {
        width: 48px;
    }
}