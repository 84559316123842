* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 25px 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-wrapper {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 780px) {
  body {
      padding: 16px 0;
  }

  .app-wrapper {
    min-height: calc(100vh - 32px);
  }
}

@font-face {
  font-family: 'GT-America';
  src: local('GT-America-Extended-Black-Trial'), url(./fonts/GT-America-Extended-Black-Trial.otf) format('opentype');
}