:root {
    --spacer-size: 125px;
}

.main_content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.spacer {
    flex: 1;
    min-height: var(--spacer-size);
}

.grid_container {
    display: grid;
    grid-template-columns: repeat(5, 200px);
    grid-template-rows: repeat(2, 200px);
    grid-gap: 20px;
    width: 1080px;
    margin: 0 auto;
    padding: var(--spacer-size) 0 0 0;
}

.grid_item {
    background-color: #232323;
    border-radius: 20px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding: 12px;
}

.grid_item.transparent_item {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.grid_item.transparent_item img {
    height: 90px;
}

.grid_item p {
    margin: 0;
    font-size: 24px;
    font-family: 'Helvetica Neue', 'Arial';
    font-weight: 500;
}

.grid_item p.white_text {
    color: #f5f5f5;
}

.grid_item p.black_text {
    color: #000000;
}

/* Merge the cells of the 2nd column vertically */
.item2 {
    grid-column: 2;
    grid-row: 1 / span 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Merge the cells of the 4th and 5th columns in the 2nd row horizontally */
.item8 {
    grid-column: 4 / span 2;
    grid-row: 2;
}

#headline_container {
    overflow: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0;
    padding-right: calc((100vw - 1080px) / 2);
}

#headline_container p {
    margin: 0;
    text-align: right;
    font-family: 'Helvetica Neue';
    font-weight: 300;
    color: #f5f5f5;
    font-size: 32px;
}

#headline_container img {
    width: 80px;
    margin: 0 60px 0 0;
}

#logo {
    height: 24px;
}

#montreal_item {
    background-image: url("../../public/images/montreal.webp");
    background-size: cover;
    background-position: center;
}

#stay_cool_item {
    background-image: url("../../public/images/stay-cool.gif");
    background-size: cover;
    background-position: center;
}

#binary_item_p {
    font-family: 'Andale Mono';
    color: #f5f5f5;
    font-size: 32px;
    text-align: center;
    margin: 0;
}

#budgetmate_app {
    background-image: url("../../public/images/budgetmate-app.jpg");
    background-size: cover;
    background-position: center;
}

#sport_item {
    background-image: url("../../public/images/reppy-app.png");
    background-size: cover;
    background-position: center;
}

#podai_item {
    background-image: url("../../public/images/podai-app.jpeg");
    background-size: cover;
    background-position: center;
}

@media only screen and (max-width: 1230px) {
    :root {
        --spacer-size: 82px;
    }

    .grid_container {
        grid-template-columns: repeat(5, 120px);
        grid-template-rows: repeat(2, 120px);
        grid-gap: 16px;
        width: 664px;
    }

    #headline_container {
        padding-right: calc((100vw - 664px) / 2);
    }

    #headline_container p {
        font-size: 22px;
    }

    #binary_item_p {
        font-size: 20px;
    }

    .grid_item {
        border-radius: 16px;
    }

    .grid_item p {
        font-size: 14px;
    }

    .grid_item.transparent_item img {
        height: 56px;
    }

    #headline_container img {
        width: 56px;
    }
}

@media screen and (max-width: 780px) {
    body {
        padding: 16px 0;
    }

    #logo {
        height: 20px;
    }

    .grid_container {
        grid-template-columns: repeat(2, 1fr);
        padding: var(--spacer-size) 16px 0 16px;
        width: 100%;
        grid-template-rows: unset;
    }

    .grid_item {
        aspect-ratio: 1 / 1;
        width: 100%;
    }

    .item2,
    .item8 {
        grid-column: auto;
        grid-row: auto;
    }

    #binary_item_p {
        width: 100%;
        font-size: 24px;
        letter-spacing: 8px;
    }

    #headline_container {
        padding-right: 16px;
        padding: 0 16px;
    }

    #headline_container p {
        max-width: 80%;
    }

    #headline_container img {
        width: 48px;
        margin: 0 10px 0 0;
    }

    .grid_item.no_show_mobile {
        display: none;
    }
}

@media screen and (max-width: 420px) {
    #binary_item_p {
        width: 100%;
        font-size: 12px;
        letter-spacing: 8px;
    }
}