.main_content {
    position: relative;
    display: block;
    padding: 0 75px 100px 75px;
}

#app_icon {
    height: 60px;
}

.section_header {
    font-weight: 900;
    font-size: 24px;
    color: white;
    margin: 32px 0 0 0;
    font-family: 'Helvetica Neue', 'Arial';
}

.section_body {
    font-weight: 400;
    font-size: 16px;
    color: white;
    margin: 16px 0 0 0;
    font-family: 'Helvetica Neue', 'Arial';
}

.list_container {
    margin: 16px 0 0 0;;
}

.list_item {
    font-weight: 400;
    font-size: 16px;
    color: white;
    font-family: 'Helvetica Neue', 'Arial';
}

.link {
    color: black;
    font-weight: 600;
}

@media only screen and (max-width: 780px) {
    .main_content {
        padding: 0 20px 100px 20px;
    }
}