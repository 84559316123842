.header_footer {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    padding: 0 75px;
    font-size: 18px;
    align-items: center;
}

.header_footer_items {
    margin: 0;
    font-family: 'Helvetica Neue', 'Arial';
    font-weight: 400;
    font-size: 16px;
}

@media only screen and (max-width: 1230px) {
    .header_footer {
        font-size: 16px;
        padding: 0 40px;
    }
}

@media only screen and (max-width: 780px) {
    .header_footer {
        padding: 0 16px;
        flex-direction: column;
        align-items: start;
        gap: 18px;
        font-size: 20px;
    }

    p.header_footer_items {
        text-align: center;
        width: 100%;
    }
}