.mainContent {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 75px 100px 75px;
    justify-content: center;
    align-items: center;
}

#appIcon {
    height: 48px;
}

.headlineSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    gap: 32px;
    padding: 300px 0;
    max-width: 600px;
    text-align: center;
}

#appStoreImage {
    width: 124px;
}

.mainHeadline {
    font-size: 28px;
    margin: 0;
    color: #000000;
    font-family: 'GT-America', -apple-system, Arial;
    font-weight: 900;
}

.headline {
    font-size: 24px;
    margin: 0;
    color: #000000;
    font-family: -apple-system, Arial;
    font-weight: 900;
    margin: 0;
}

.contentSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 32px;
    padding: 0 0 100px 0;
}

.halfSection {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 500px;
}

.halfSection p {
    text-align: center;
    font-size: 20px;
    color: #000000;
    max-width: 350px;
    font-family: -apple-system, Arial;
    font-weight: 900;
}

.imageContainer {
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 500px;
    width: 100%;
    border-radius: 16px;
}

@media only screen and (max-width: 780px) {
    .mainContent {
        padding: 0 20px 132px 20px;
    }

    .headlineSection {
        padding: 200px 0;
    }
}

@media only screen and (max-width: 660px) {
    .contentSection {
        flex-direction: column;
    }

    .halfSection {
        width: 100%;
    }
}